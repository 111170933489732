import { Form, message, Spin, Tooltip } from 'antd'
import type { FormInstance } from 'antd/es/form/hooks/useForm'
import { useBoolean, useRequest } from 'ahooks'
import { useRef } from 'react'
import copy from 'copy-to-clipboard'
import {
  AppLogo,
  IconFont,
  TextArea,
  usePageModal,
  Select,
  JsonEditor,
} from '@/components'
import type { nodeConfigType } from '@/apis/run.ts'
import { SkillFlowViewMode, getFirstNodeConfig } from '@/apis/run.ts'
import { FieldTypes } from '@/features/nodes/start'
import type { AgentSkillBaseRequest } from '@/apis/agent.ts'
import { AgentFormItem } from '@/features/agent/components/FormItem.tsx'
import type { ApplicationBaseInfo } from '@/features/agent/types'
import { useWorkspaceStore } from '@/store'
import type { AutoDescButtonInstance } from '../components/AutoDescButton'
import { AutoDescButton } from '../components/AutoDescButton'
import { FiledTypeSelectOptions } from '@/features/nodes/start/components/FieldTypeSelect'
import { InputsViewModeSelect } from '@/features/agent/SkillTabs/InputsViewModeSelect.tsx'
import { FLOW_OUTPUT_MODE_OPTIONS } from '../const'

export interface SkillEditProps {
  form: FormInstance
  flowId: string
  applicationInfo: ApplicationBaseInfo
  defaultValue?: SkillEditDefaultValue
  onLoading?: (loading: boolean) => void
  onConfigFetched?: (config: nodeConfigType) => void
}

export interface SkillEditDefaultValue {
  description: string
  inputs: AgentSkillBaseRequest['inputs']
  outputs: AgentSkillBaseRequest['outputs']
}

const FieldTypeMap: Partial<{
  [K in (typeof FieldTypes)[keyof typeof FieldTypes]]: string
}> = {
  [FieldTypes.MultiSelect]: FieldTypes.MultiSelect,
  [FieldTypes.Json]: 'json',
  [FieldTypes.File]: 'string',
}

const exampleJsonSchema = {
  type: 'object',
  properties: {
    name: {
      type: 'string',
      description: '姓名',
    },
    age: {
      type: 'number',
      description: '年龄',
    },
  },
  required: ['name', 'age'],
}

export function SkillEdit(props: SkillEditProps) {
  const {
    form,
    flowId,
    applicationInfo,
    defaultValue,
    onLoading,
    onConfigFetched,
  } = props

  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )

  const pageModal = usePageModal()

  const [generating, { set: setGenerating }] = useBoolean(false)

  const AutoDescButtonRef = useRef<AutoDescButtonInstance>(null)

  const onAutoLoading = (loading: boolean) => {
    setGenerating(loading)
    onLoading?.(loading)
  }

  const { loading: fetchFirstNodeLoading, data: nodeConf } = useRequest(
    getFirstNodeConfig,
    {
      defaultParams: [
        {
          flow_id: flowId,
        },
      ],
      onSuccess: res => {
        onConfigFetched?.(res)
        const defaultInputsDescriptionMap: Record<string, any> = {}
        defaultValue?.inputs?.forEach(item => {
          defaultInputsDescriptionMap[item.key] = item.description
        })

        const inputsViewMode =
          res?.inputs_properties?.inputsViewMode ?? SkillFlowViewMode.RAW

        form.setFieldsValue({
          description: res?.skill_desc ?? res?.desc_schema?.description ?? '',
          inputs_properties: res?.inputs_properties ?? {
            inputsViewMode,
            formWelcome: '',
          },
          inputs:
            res.form_config?.map(item => {
              const defaultValue =
                item.type === FieldTypes.Json ? '' : item.label
              const currSchema = res?.desc_schema?.inputs?.find(
                input => input.key === item.variableName,
              )
              return {
                key: item.variableName,
                field_type: FieldTypeMap[item.type] ?? 'string',
                description:
                  currSchema?.description ??
                  defaultInputsDescriptionMap[item.variableName] ??
                  defaultValue ??
                  '',
                name: item.label,
                required: item.required,
              }
            }) ?? [],
          output_mode:
            defaultValue?.outputs?.find(item => item.key === 'output_mode')
              ?.value || 'agent_processed',
          outputs: defaultValue?.outputs ? defaultValue?.outputs : [],
        })
      },
    },
  )

  const getKeyText = (key: string) => {
    const type = nodeConf?.form_config?.find(v => v.variableName === key)?.type
    return FiledTypeSelectOptions?.find(v => v.value === type)?.title
  }
  if (fetchFirstNodeLoading) {
    return (
      <div className='w-full h-612px flex-center'>
        <Spin />
      </div>
    )
  }

  const handleAutoDesc = () => {
    AutoDescButtonRef?.current?.generate()
    // if (form.getFieldValue('description')) {
    //   AutoDescButtonRef?.current?.show()
    // } else {
    //   AutoDescButtonRef?.current?.generate()
    // }
  }

  const onInputViewModeChange = (mode: string) => {
    if (mode === SkillFlowViewMode.FORM) {
      form.setFieldValue('output_mode', 'direct_output')
    }
  }

  return (
    <div>
      {applicationInfo && (
        <div
          onClick={() => {
            pageModal.show({
              url: `/application/${currentWorkspaceId}/${applicationInfo.id}/flow/${applicationInfo.flowId}`,
            })
          }}
          className='flex items-center cursor-pointer'
        >
          <AppLogo
            size={40}
            color={applicationInfo.color}
            value={applicationInfo.icon}
            type='emoji'
            fillSize={20}
          />
          <span className='ml-12px font-medium text-16px/20px text-font'>
            {applicationInfo.name}
          </span>
          <span className='text-16px ml-8px w-24px h-24px hover:bg-bg_3 hover:bg-op-12 c-#626999 c-op-60 flex-center'>
            <IconFont name='super-link' />
          </span>
        </div>
      )}
      <div className='relative'>
        <AutoDescButton
          flowId={applicationInfo.flowId}
          ref={AutoDescButtonRef}
          className='absolute right-6 top-2 z-10'
          onLoading={onAutoLoading}
          trigger='manual'
          onGenerated={flowSchemaResult =>
            form.setFieldsValue(flowSchemaResult)
          }
        >
          <div
            className='text-primary text-12px cursor-pointer flex hover:text-opacity-60 items-center'
            onClick={handleAutoDesc}
          >
            <IconFont name='auto' className='text-16px'></IconFont>
            <p className='ml-4 mt-1px text-14px'>自动生成描述</p>
          </div>
        </AutoDescButton>
        <Form
          form={form}
          layout='vertical'
          className='mt-24px'
          disabled={generating}
          requiredMark={false}
        >
          <AgentFormItem
            required
            label='技能描述'
            name='description'
            rules={[{ required: true, message: '技能描述不能为空' }]}
          >
            <TextArea
              className='!resize-none'
              autoSize={{
                minRows: 2,
              }}
              showCount
              maxLength={300}
              placeholder='告诉Agent这个技能可以做什么，让它能更好的调用'
            />
          </AgentFormItem>
          <AgentFormItem label='输入设置' required>
            <Form.List name='inputs'>
              {fields => {
                if (!fields.length) {
                  return (
                    <div className='py-10 c-font_1 c-op-60'>该Flow无需输入</div>
                  )
                }
                return (
                  <>
                    <div className='flex py-8 items-center h-38px bg-bg_3 rd-8px bg-op-4 font-500 text-14px mt-4 text-font_1'>
                      <span className='pl-16px w-128px truncate'>参数名称</span>
                      <span className='w-106px truncate'>类型</span>
                      <div className='flex items-center flex-1'>
                        <span>告诉 Agent 参数的描述</span>
                        {/* <span className='ml-5 text-error'>*</span> */}
                        <Tooltip
                          title='告诉 Agent 这个变量的含义，让它能更好的调用'
                          placement='top'
                        >
                          <IconFont
                            name='jieshishuimeng'
                            className='ml-5 text-font_1 text-op-40 cursor-pointer'
                          />
                        </Tooltip>
                      </div>
                      <div className='pl-0px pr-12px'>必填</div>
                    </div>
                    {fields.map(inputItem => {
                      const inputValue = form.getFieldValue([
                        'inputs',
                        inputItem.name,
                      ])
                      return (
                        <div
                          className='border-none last-of-type:mb-0 text-14px'
                          key={inputItem.key}
                        >
                          <div className='font-400 flex items-center py-12 pl-16'>
                            <span className='pr-12 flex items-center w-112px'>
                              {inputValue.field_type === FieldTypes.Json && (
                                <IconFont
                                  name='json'
                                  className='c-primary mr-5'
                                />
                              )}
                              <span
                                className='truncate flex-1 text-14px'
                                title={inputValue.key}
                              >
                                {inputValue.key}
                              </span>
                            </span>
                            <div className='w-103px'>
                              {getKeyText(inputValue.key as string)}
                            </div>
                            <AgentFormItem
                              required
                              name={[inputItem.name, 'description']}
                              tooltip='告诉Agent这个变量的含义，让它能更好的调用'
                              className='mb-0 flex-1 '
                              rules={[
                                {
                                  required: true,
                                  message: '变量描述不能为空',
                                },
                                {
                                  validator: (_rule, value, callback) => {
                                    if (
                                      inputValue.field_type ===
                                        FieldTypes.Json &&
                                      typeof value === 'string'
                                    ) {
                                      try {
                                        JSON.parse(value)
                                        callback()
                                      } catch (e) {
                                        callback('请输入有效的JSON SCHEMA')
                                      }
                                    } else {
                                      callback()
                                    }
                                  },
                                },
                              ]}
                            >
                              {inputValue.field_type === FieldTypes.Json ? (
                                <JsonEditor
                                  setOptions={{
                                    minLines: 17,
                                    hasCssTransforms: false,
                                  }}
                                  placeholder={JSON.stringify(
                                    exampleJsonSchema,
                                    null,
                                    2,
                                  ).replaceAll(' ', '  ')}
                                  onCopy={text => {
                                    copy(text)
                                    message.success('内容已复制到剪切板')
                                  }}
                                />
                              ) : (
                                <TextArea
                                  autoSize={{
                                    minRows: 1,
                                  }}
                                  className='!resize-none !min-h-40px !pt-8'
                                  placeholder='说明这个参数代表什么意思'
                                />
                              )}
                            </AgentFormItem>
                            <div className=' ml-12px w-40px'>
                              {inputValue.required ? '必填' : ''}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </>
                )
              }}
            </Form.List>
          </AgentFormItem>
          <AgentFormItem
            name={['inputs_properties', 'inputsViewMode']}
            label='输入展示设置'
            required={true}
          >
            <InputsViewModeSelect onChange={onInputViewModeChange} />
          </AgentFormItem>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, nextValues) =>
              prevValues.inputs_properties?.inputsViewMode !==
              nextValues.inputsViewMode?.inputsViewMode
            }
          >
            {form => {
              const inputsViewMode = form.getFieldValue([
                'inputs_properties',
                'inputsViewMode',
              ])
              return (
                <>
                  <Form.Item
                    noStyle
                    hidden={inputsViewMode === SkillFlowViewMode.RAW}
                  >
                    <AgentFormItem
                      label='表单开场白'
                      name={['inputs_properties', 'formWelcome']}
                    >
                      <TextArea placeholder='例如，请填写表单' />
                    </AgentFormItem>
                  </Form.Item>
                  <AgentFormItem label='输出设置' name='output_mode'>
                    <Select
                      // disabled={inputsViewMode === SkillFlowViewMode.FORM}
                      options={
                        inputsViewMode === SkillFlowViewMode.FORM
                          ? FLOW_OUTPUT_MODE_OPTIONS.filter(
                              v => v.value !== 'agent_processed',
                            )
                          : FLOW_OUTPUT_MODE_OPTIONS
                      }
                    />
                  </AgentFormItem>
                </>
              )
            }}
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
