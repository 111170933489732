export const FLOW_OUTPUT_MODE_OPTIONS = [
  {
    label: '经过 Agent 处理后输出',
    value: 'agent_processed',
  },
  {
    label: '不做处理直接返回结果',
    value: 'direct_output',
  },
  {
    label: '不回复信息',
    value: 'no_output',
  },
]
